import { createStore } from 'vuex'
import Auth from './Auth'
import Support from "./Support";
import Products from "./Products";
import Cart from "./Cart";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    Products,
    Support,
    Cart
  }
})
