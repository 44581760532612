import { createRouter, createWebHistory } from 'vue-router'

// import LandingPage from '../views/public/LandingStore.vue';
import LandingPage from '../views/public/Temp.vue';
// import LandingNext from '../views/public/LandingNext.vue';

// import AuthLogin from '../views/Auth/Login.vue';

// import DashboardOverview from '../views/Dashboard/Overview.vue';
// import BillingOverview from '../views/Billing/Overview.vue';
// import ResourcesOverview from '../views/Resources/Overview.vue';
// import SupportOverview from '../views/Support/Overview.vue';

import ErrorNotFound from '../views/errors/NotFound.vue';
import store from "../store";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
    meta: {title: "ExpressiveLabs Store"}
  },
  // {
  //   path: '/mikoto',
  //   name: 'Mikoto',
  //   component: MikotoSpotlight,
  //   meta: {title: "Mikoto Studio is here"}
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: AuthLogin
  // },
  // {
  //   path: '/products',
  //   name: 'ProductsList',
  //   component: () => import('../views/public/Products.vue'),
  //   meta: {title: "Products - ExpressiveLabs Store"}
  // },
  // {
  //   path: '/products/:sku',
  //   name: 'ProductDetail',
  //   component: () => import('../views/public/ProductPage.vue'),
  //   meta: {title: "Product - ExpressiveLabs Store"},
  //   props: true
  // },
  // {
  //   path: '/cart',
  //   name: 'Cart',
  //   component: () => import('../views/public/Cart.vue'),
  //   meta: {title: "Shopping cart - ExpressiveLabs Store"}
  // },
  // {
  //   path: '/software-center',
  //   name: 'SoftwareCenter',
  //   component: () => import('../views/public/SoftwareCenter.vue'),
  //   meta: {title: "Software Center - ExpressiveLabs Store"}
  // },
  // {
  //   path: '/account/activate',
  //   name: 'Activate',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/public/Activate.vue'),
  //   meta: {title: "Activate your Mikoto account"}
  // },
  // {
  //   path: '/account',
  //   name: 'Dashboard',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/portal/Account.vue'),
  //   meta: { requiresAuth: true, title: "Account - ExpressiveLabs Store" }
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: ErrorNotFound,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authUser = store.getters["Auth/user"];
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
  const loginQuery = {path: "/login", query: {redirect: to.fullPath}};
  
  if (reqAuth && !authUser) {
    store.dispatch("Auth/me").then(() => {
      if (!store.getters["Auth/authenticated"]) {
        next(loginQuery);
      } else {
        next();
      }
    });
  } else {
    next(); // make sure to always call next()!
  }
});

router.afterEach((to) => {
  document.title = to.meta.title || "Mikoto Studio";
});

export default router
