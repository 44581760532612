import axios from "axios";

export default {
  namespaced: true,
  
  state: {
    items: [],
    skus: [],
    total: 0.0,
    vat: 0.0
  },
  getters: {
    items(state) {
      return state.items
    },
    totalQuantity(state) {
      let sum = 0;
      
      for(let i = 0; i < state.items.length; i++) {
        sum += state.items[i].amount;
      }

      return sum;
    }
  },
  mutations: {
    ADD_TO_CART(state, value) {
      let existing = state.items.findIndex(r => {
        return r.sku === value.sku;
      });

      if(existing > -1) {
        state.items[existing].amount += 1;
      } else {
        state.items.push(value);
      }
    }
  },
  actions: {
    addToCart({ commit }, data) {
      commit('ADD_TO_CART', data);
      // Store the updated cart, either to API or to a cookie
    },
    loadCart({ commit }) {
      return axios.get('/api/store/consumer/cart').then(res => {
        commit('SET_CART', res.data);
      });
    },
  }
}
