<template>
  <router-link to="/" class="flex flex-row items-center mb-2 justify-center">
    <img src="/logo.png" class="h-10 mr-1" alt="">

    <h3 class="text-lg font-light mr-2"><span class="font-semibold">Expressive</span>Labs</h3>
  </router-link>
</template>

<script>
export default {
  name: "AppLogo"
}
</script>

<style scoped>

</style>