import axios from "axios";

export default {
  namespaced: true,
  
  state: {
    messages: [],
    loaded_history: false
  },
  getters: {
    messages(state) {
      return state.messages
    },
    loadedHistory(state) {
      return state.loaded_history
    }
  },
  mutations: {
    SET_MESSAGES(state, value) {
      state.messages = value.reverse();
    },
    APPEND_MESSAGE(state, value) {
      state.messages.unshift(value);
    },
    TOGGLE_LOADED_HISTORY(state) {
      state.loaded_history = !state.loaded_history;
    }
  },
  actions: {
    loadMessages({ commit }) {
      return axios.get('/api/support/messages').then(res => {
        commit('SET_MESSAGES', res.data);
        commit('TOGGLE_LOADED_HISTORY');
      });
    },
    sendMessage({ commit }, message) {
      return axios.post('/api/support/messages', message).then(() => {
        commit('APPEND_MESSAGE', message);
      });
    }
  }
}
