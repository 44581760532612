<template>
  <div class="p-8">
    <h1 class="font-bold text-3xl">
      <span class="text-blue">404</span>
      Not Found
    </h1>
    <small>Welp, that didn't go as planned...</small>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>

<style scoped>

</style>