import axios from "axios";

export default {
  namespaced: true,
  
  state: {
    products: [],
    recommended: [],
  },
  getters: {
    products(state) {
      return state.products
    },
    recommended(state) {
      return state.recommended
    },
  },
  mutations: {
    SET_PRODUCTS(state, value) {
      state.products = value;
    },
    SET_RECOMMENDED(state, value) {
      state.recommended = value;
    }
  },
  actions: {
    loadProducts({ commit, state }) {
      if(state.products.length === 0) {
        return axios.get('/api/store/products').then(res => {
          for(let product in res.data) {
            if(product.theme === null) {
              product.theme = {
                background_color: '#ffffff',
              }
            }
          }

          commit('SET_PRODUCTS', res.data);
        });
      }
    },
    getProduct({ state }, sku) {
      return state.products.find(r => {
        return r.sku === sku;
      });
    }
  }
}
