<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
  <div>
    <router-view />
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  // import AppLayout from "./layouts/AppLayout";

  export default {
    // components: {AppLayout},
    methods: {
      ...mapActions({
        xLogout: 'Auth/signOut'
      }),
      logout() {
        this.xLogout().then(() => {
          this.$router.push('/login');
        });
      }
    },
    computed: {
      ...mapGetters({
        authenticated: 'Auth/authenticated',
        user: 'Auth/user'
      })
    }
  }
</script>

<style lang="scss">
  .mikoto-gradient-text {
        background: -webkit-linear-gradient(top left, #FF954F, #FF6363);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>
