import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  
  state: {
    authenticated: false,
    user: null,
    token: null
  },
  
  getters: {
    authenticated (state) {
      return state.authenticated
    },
    
    user (state) {
      return state.user
    },
    
    token (state) {
      return state.token
    },
  },
  
  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
    SET_USER (state, value) {
      state.user = value
    },
    SET_TOKEN (state, value) {
      state.token = value
    }
  },
  
  actions: {
    async signIn ({ commit, dispatch }, credentials) {
      await axios.post('/api/authenticate', credentials).then(res => {
        this.token = res.data;
        commit('SET_TOKEN', res.data)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        Cookies.set("api_token", this.token);
      });
    
      return dispatch('me')
    },
  
    async signOut ({ dispatch }) {
      await axios.post('/api/logout').then(() => {
        Cookies.remove('api_token');
        axios.defaults.headers.common['Authorization'] = '';
      });
    
      return dispatch('me')
    },
  
    me ({ commit }) {
      if(this.token == null && Cookies.get('api_token')) {
        commit('SET_TOKEN', Cookies.get('api_token'))
        this.token = Cookies.get('api_token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
      }

      console.log(this.token);

      return axios.get('/api/users/auth').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }
  }
}