<template>
    <div class="bg-gray-900 min-h-screen">
        <div class="flex flex-row w-full">
            <div class="flex-auto"></div>
            <div class="w-4/5 md:w-2/3 xl:w-1/3 flex flex-col min-h-screen">
                <div class="p-4">
                    <app-logo-center class="text-white"></app-logo-center>
                </div>

                <div class="flex-auto"></div>
                <div class="bg-white p-4 rounded">
                    <h4 class="text-lg font-semibold">Notice</h4>
                    <p class="text-sm">
                        The ExpressiveLabs Store is currently under construction. If you're interested in our products, check out our singing synthesizer in development, <a href="https://mikoto.studio/" class="text-orange-dark underline">Mikoto Studio</a>, and sign up for the waitlist!
                    </p>
                </div>
                <div class="flex-auto"></div>
            </div>
            <div class="flex-auto"></div>
        </div>
    </div>
</template>

<script>
    import AppLogoCenter from '../../components/AppLogoCenter.vue';

    export default {
        components: {
            AppLogoCenter
        },
        data() {
            return {
                
            }
        },
        created() {
            
        }
    }
</script>