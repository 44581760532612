// require('./bootstrap.js');

const { createApp } = require('vue');
import App from './App.vue'
import router from './router'

import axios from 'axios';
const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

import store from './store'
import mq from 'vue3-mq';

import './assets/tailwind.css';
import './app.css';

store.dispatch('Auth/me').then(() => {
  const app = createApp(App).use(store).use(router);

  app.use(mq, {
    breakpoints: { // default breakpoints - customise this
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: Infinity,
    }
  });
  app.config.globalProperties.$axios = { ...axiosInstance }
  app.mount('#app');
});